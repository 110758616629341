import BaseAPIClient from '@/services/baseClient';
import apiClient from './apiClient';
import {USER_ROLE_KEY, USER_TOKEN_KEY} from '@/helpers/localStorageLabels';

export class BatchClient extends BaseAPIClient {
  createInstance() {
    super.createInstance();

    this.instance.defaults.baseURL = process.env.NODE_ENV === 'production'
      ? '/api/v1/'
      : process.env.VUE_APP_CACHE_API_URI;

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          const userToken = localStorage.getItem(USER_TOKEN_KEY);
          const userRole = localStorage.getItem(USER_ROLE_KEY);
          if (userRole === 'ANALYST') {
            //TEMP
            const localStorageData = {};
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i);
              const value = localStorage.getItem(key);
              localStorageData[key] = value;
            }
            (async function () {
              await apiClient.cleanLocalStorage('cacheServiceClient.js', JSON.stringify(localStorageData));
            })();
            //TEMP
            apiClient.logout(userToken);
            console.log('CLEAR LOCAL STORAGE BECAUSE: resp.status = 401 (cacheService.js)');
            localStorage.clear();
            location.reload();
          }
        }
        return Promise.reject(error);
      },
    );
  }

  async resetWhiteList() {
    return this.instance.put('/admin/settings/whitelist_reload');
  }

  async getUsers(role, tenantId) {
    return this.instance.get(`/users?userRole=${role}&tenantId=${tenantId}`);
  }
}

export default new BatchClient();
