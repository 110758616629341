// async getSupervisorWorkers({}, { supervisor }) {
//   const query = { managerId: supervisor };
//   const { data } = await supervisionClient.getWorkers(query);
//   return data;
// },

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_ERROR = 'USERS_ERROR';

export const SUPERVISORS_REQUEST = 'SUPERVISORS_REQUEST';
export const SUPERVISORS_SUCCESS = 'SUPERVISORS_SUCCESS';
export const SUPERVISORS_ERROR = 'SUPERVISORS_ERROR';