import supervisionClient from '@/services/supervisionClient';
import apiClient from '@/services/apiClient';

import { USERS_REQUEST } from '../actions/users';
import cacheServiceClient from '@/services/cacheServiceClient';
import {TENANT_ID_KEY, USER_ROLE_KEY} from '@/helpers/localStorageLabels';
import {DASH_DASH_EMPTY_VALUE, noAnalysts} from '@/helpers/dataLabels';
import {rolesObject} from '@/helpers/roles';
import ActionRoles from '@/helpers/actionRoles';

const state = {
  analystsOnline: [],
  analystsAll: [],
  supervisors: [],
  roles: ActionRoles.roles,
};

const getters = {
  roleList: (state) => state.roles,
  analystsOnline: (state) => state.analystsOnline,
  analystsAll: (state) => state.analystsAll,
  supervisors: (state) => state.supervisors,
};

const actions = {
  [USERS_REQUEST]: async ({ commit }, userId) => {
    const userRole = localStorage.getItem(USER_ROLE_KEY);
    const tenantId = localStorage.getItem(TENANT_ID_KEY);

    //Get all analysts for current tenant
    //And transform to default interface [{username: ..., _id:...},{}]
    const {data: {resultData: allAnalysts}} = await cacheServiceClient.getUsers(rolesObject.ANALYST, tenantId);
    const allAnalystsArray = [];
    for (const [key, value] of Object.entries(allAnalysts)) {
      allAnalystsArray.push({username: value, _id: key});
    }
    allAnalystsArray.push({username: noAnalysts, _id: DASH_DASH_EMPTY_VALUE});
    commit({ type: 'setAnalystsAll', data: allAnalystsArray });

    let response = {};
    if (userRole === rolesObject.SUPERVISOR) {
      response = await apiClient.getSessionsByTenantId(tenantId);
      const filteredList = response.data.resultData.filter((session) => session.user.managerId === userId).map((session) => session.user);
      commit({ type: 'setAnalystsOnline', data: filteredList });
    } else {
      response = await supervisionClient.getSupervisors(userId);
      const filteredList = [];

      for (const supervisor of response.data) {
        const usersSessions = await apiClient.getSessionsByTenantId(tenantId);

        const currentSupervisorAnalysts = usersSessions.data.resultData.filter((session) => session.user.managerId === supervisor._id).map((session) => session.user);
        filteredList.push(...currentSupervisorAnalysts);
      }
      
      commit({ type: 'setAnalystsOnline', data: filteredList });
      commit({ type: 'setSupervisors', data: response.data.resultData });
    }
  },
    
};

const mutations = {
  setSupervisors(state, { data }) {
    state.supervisors = data;
  },
  setAnalystsOnline(state, { data }) {
    state.analystsOnline = data;
  },
  setAnalystsAll(state, { data }) {
    state.analystsAll = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
