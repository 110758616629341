import axios from 'axios';
import BaseAPIClient from './baseClient';
import {TIME_FRAME_IN_MINUTES_KEY, TIMEZONE_KEY, USER_ID_KEY} from '@/helpers/localStorageLabels';
import {DASH_DASH_EMPTY_VALUE, SOLUTIONS} from '@/helpers/dataLabels';
import { capitalize } from 'lodash';

export class QuerylogSupervisionClient extends BaseAPIClient {
    constructor() {
        super();

        this.instance.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api/v1/supervision' : process.env.VUE_APP_SUPERVISION_API_URI;

        this.instance.defaults.transformResponse = [].concat(axios.defaults.transformResponse, (data) => data.resultData);
    }

    async getManagers(query) {
        query = JSON.stringify(query);
        return this.instance.get(`/managers/${query}`);
    }

    async getSupervisors() {
        const userId = localStorage.getItem(USER_ID_KEY);
        return this.instance.get(`/supervisors/${userId}`);
    }

    async getSupervisor({ _id }) {
        return this.instance.get(`/supervisors/${_id}`);
    }

    async addSupervisor(supervisor) {
        return this.instance.post('/supervisors', supervisor);
    }

    async updateSupervisor({ _id, ...data }) {
        return this.instance.patch(`/supervisors/${_id}`, data);
    }

    async deleteSupervisor({ _id }) {
        return this.instance.delete(`/supervisors/${_id}`);
    }

    async getWorkers(query) {
        if (query) {
            // query = JSON.stringify(query);
            return this.instance.get(`/workers/${query}`);
        }
        return [];
    }

    async addWorker(worker) {
        return this.instance.post('/workers', worker);
    }

    async updateWorker({ id, ...data }) {
        return this.instance.patch(`/workers/${id}`, data);
    }

    async deleteWorker(query) {
        const { id } = query;
        return this.instance.delete(`/workers/${id}`);
    }

    async getAllQuerylogs() {
        return this.instance.get('/querylogs/sys-admin');
    }

    async getUserQuerylogs(
        {userId,
        finalStatus,
        limit = 1000,
        skip = 0,
        sortBy = 'timePending',
        sortDesc = true,
        workerIdForFilter,
        time_range,
        newer,
        type,
        status,
    }) {
        let getRequestString = `querylogs/${userId}?limit=${limit}&skip=${skip}&sortBy=${sortBy}&sortDesc=${sortDesc}`;
        if (finalStatus) getRequestString += `&finalStatus=${finalStatus}`;
        if (workerIdForFilter) {
            //filter querylogs with workerId = null
            workerIdForFilter = (workerIdForFilter === DASH_DASH_EMPTY_VALUE) ? null : workerIdForFilter;
            getRequestString += `&workerId=${workerIdForFilter}`;
        }
        // concatenating newer with string to catch 0 value as well
        if (newer + '') getRequestString += `&timeframe=${time_range}&newer=${newer}`;
        if (type) getRequestString += `&type=${type}`;
        if (status) getRequestString += `&status=${status}`;

        return this.instance.get(getRequestString);
    }
        
    async getPkycQuerylogs({
        userId,
        finalStatus,
        limit = 1000,
        skip = 0,
        sortBy = 'timePending',
        sortDesc = true,
        workerIdForFilter,
        time_range,
        newer,
        type,
        status,
        pkycType,
    }) {
        const timezone = localStorage.getItem(TIMEZONE_KEY);

        let getRequestString = `pkyc/${userId}?limit=${limit}&skip=${skip}&sortBy=${sortBy}&sortDesc=${sortDesc}&timezone=${timezone}`;
        if (finalStatus) getRequestString += `&finalStatus=${finalStatus}`;
        if (workerIdForFilter) getRequestString += `&workerId=${workerIdForFilter}`;
        if (newer + '') getRequestString += `&timeframe=${time_range}&newer=${newer}`;
        if (type) getRequestString += '&type=pkyc';
        if (status) getRequestString += `&status=${status}`;
        if (pkycType) getRequestString += `&pkycType=${capitalize(pkycType)}`;
        return this.instance.get(getRequestString);
    }

    async getReportStatistics({ tenantId, timezone, timeStamp, type }) {
        const queryParams = {
          timezone: timezone || 'UTC',
          timeStamp,
          type,
        };
    
        const queryString = Object.keys(queryParams)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
          .join('&');
    
        return this.instance.get(`/report/statistics/${tenantId}?${queryString}`);
    }

    async getQuerylogsForSupervisor(params) {
        return this.instance.get(`/querylogs/supervisor/${params.user}?status=${params.status}`);
    }

    async getStatisticForChiefOrSupervisor(params) {
        const timezone = localStorage.getItem(TIMEZONE_KEY);
        const timeRange = localStorage.getItem('timeRange') || 'day';
        if (!params.type) params.type = SOLUTIONS.wire;

        const timeFramesObject = JSON.parse(localStorage.getItem(TIME_FRAME_IN_MINUTES_KEY));
 
        let timeFrameInMinutes = timeFramesObject[`${params.type}Timeframe`] || 180;
        // setting 6 months for timeframe if it defines as 'never'
        if (typeof timeFrameInMinutes === 'string') timeFrameInMinutes = 1440 * 30 * 6;

        let requestString = `/statistics/${params.user}?timezone=${timezone}&timeRange=${timeRange}&type=${params.type}&timeFrameInMinutes=${timeFrameInMinutes}`;
        if (params.pkycType) requestString += `&pkycType=${capitalize(params.pkycType)}`;
        if (params.tableRequest) requestString += `&tableRequest=${params.tableRequest}`;
        if (params.finalStatus) requestString += `&finalStatus=${params.finalStatus}`;
        if (params.chosenSupervisorId) requestString += `&chosenSupervisorId=${params.chosenSupervisorId}`;

        return this.instance.get(requestString);
    }

    async getQuerylogsForChiefSupervisor(params) {
        return this.instance.get(`/querylogs/chiefsupervisor/${params.user}?status=${params.status}&tenantId=${params.tenantId}`);
    }

    async addQuerylog() {
        return this.instance.get('/querylogs');
    }

    async updateQuerylog({ action, ...payload }, isPkyc=false) {
        const validActionTypes = ['assign', 'close', 'reopen', 'submit', 'approve'];
    
        if (!validActionTypes.includes(action)) {
          throw new Error(`Invalid action type: '${action}'`);
        }
    
        const querylogs = payload.querylogs.map((querylog) => typeof querylog === 'string' ? querylog : {
          _id: querylog._id || querylog.id,
          updatedAt: querylog.updatedAt,
        });

        payload.querylogs = querylogs;
        if (action === 'reopen' || action === 'submit' || action === 'approve') {
          const filesToAdd = [...payload.history.files];
          payload.history.files.length = 0;
          const response = await this.instance.put(`${isPkyc ? '/pkyc' : ''}/querylogs/${action}`, payload);
          
          if (response.status !== 200) {
            throw new Error('Server error');
          }
    
          for (const file in filesToAdd) {
            const formData = new FormData();
            formData.append('files', filesToAdd[file]);
            await this.instance.put(`/querylogs/addFiles/${isPkyc ? 'pkyc/' : ''}${response.data[0].id}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          }
    
          return response;
        } else {
          return this.instance.put(`${isPkyc ? '/pkyc' : ''}/querylogs/${action}`, payload);
        }
      }

    async updateSearchFieldStatus(loadData) {
        const {payload, files} = loadData;
        const { id } = payload;
        const res = await this.instance.patch(`/querylogs/${id}/update-search-field-status`, [payload]);
    
        const filesToAdd = [...files];
        for (const file in filesToAdd) {
          const formData = new FormData();
          formData.append('files', filesToAdd[file]);
          await this.instance.put(`/querylogs/addFiles/${id}/searchedName/${payload.searchedName}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
    
        return res;
      }

    async getDailyReport({ userRole, userId }) {
        const validRoles = ['supervisor', 'worker'];

        if (!validRoles.includes(userRole)) {
            throw new Error(`Invalid user role: '${userRole}'`);
        }

        return this.instance.get(`/querylogs/report/${userRole}/${userId}`);
    }
}

export default new QuerylogSupervisionClient();
