import { useWebSocket } from '@vueuse/core';
import {useToast} from 'vue-toast-notification';

export default () => {
    const $toast = useToast();

    const hostForWEbsocket = window.location.host.includes('localhost') ? 'ws://localhost:4567/api/v1/ws/' : `wss://${window.location.host}/api/v1/ws/`;
    
    // console.log(`Connecting to WebSocket at ${hostForWEbsocket}`);
    
    const { send, status, open, close } = useWebSocket(hostForWEbsocket, {
        onConnected: () => {
            console.log('WebSocket connected');
        },
        onDisconnected: () => {
            console.log('WebSocket disconnected');
        },
        onError: (wsInstance, event) => {
            console.error('WebSocket error:', {
                message: event.message || 'Unknown error',
                type: event.type,
                target: event.target,
                timeStamp: event.timeStamp,
            });
        },

        onMessage: (_, event) => {
            const messageData = event.data;

            if (messageData.includes('logout')) {
                $toast.error('You\'ve been logged out');

                setTimeout(() => {
                    $toast.clear();
                    window.location.reload();
                }, 2500);
                return; // Ensures no further code executes in this callback
            }

            const token = localStorage.getItem('user-token');
            if (token) {
                send(`custom-pong, token >>>${token}<<<`);
            } else {
                // console.log('No token found in localStorage');
                return;
            }
        },
    });

};
