/* eslint no-shadow: ["error", { "allow": ["state"] }] */
import apiClient from '@/services/apiClient';
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, SET_TOKEN_LOCALSTORAGE, AUTH_LOGOUT } from '@/store/actions/auth';
import {
  USER_TOKEN_KEY,
  USER_ROLE_KEY,
  USER_READ_ONLY_KEY,
  USER_NAME_KEY,
  USER_ID_KEY,
  TENANT_ID_KEY,
  DATE_FORMAT_KEY,
  TIMEZONE_KEY,
  SOLUTIONS_KEY,
  TIME_FRAME_IN_MINUTES_KEY,
  SHOULD_PERSIST_KEY,
  SESSION_ID_KEY,
  COMPARE_NAMES_KEY,
  SHOW_PKYC,
  PKYC_ENTITIES,
  USE_BATCH,
} from '@/helpers/localStorageLabels';
import {ERROR_STATUS, LOADING_STATUS, SUCCESS_STATUS} from '@/helpers/statusLabels';
import { rolesObject } from '@/helpers/roles';
import { SOLUTIONS } from '@/helpers/dataLabels';

const state = {
  token: localStorage.getItem(USER_TOKEN_KEY) || '',
  role: localStorage.getItem(USER_ROLE_KEY) || '',
  readOnlyUser: localStorage.getItem(USER_READ_ONLY_KEY),
  userName: localStorage.getItem(USER_NAME_KEY) || '',
  userId: localStorage.getItem(USER_ID_KEY) || '',
  tenantId: localStorage.getItem(TENANT_ID_KEY) || '',
  userProfile: null,
  status: '',
  hasLoadedOnce: false,
  errors: null,
  managerId: '',
  passwordResetRequired: null,
  use2FA: false,
};

const getters = {
  isAuthenticated: (state) =>
    !!(state.token && state.token !== 'undefined' && state.token !== '' && state.token !== null),
  authStatus: (state) => state.status,
  hasError: (state) => state.status === ERROR_STATUS,
  userRole: (state) => state.role,
  readOnly: (state) => state.readOnlyUser,
  userName: (state) => state.userName,
  userId: (state) => state.userId,
  tenantId: (state) => state.tenantId,
  managerId: (state) => state.managerId,
  userGroup: (state) => state.userGroup,
  errors: (state) => state.errors,
  passwordResetRequired: (state) => state.passwordResetRequired,
  use2FA: (state) => state.use2FA,
  tenantMlThreshold: (state) => state.userProfile.tenantOptions.global?.mlThreshold || 0.7,
  limitInWorkingHours: (state) => state.userProfile.tenantOptions.batch?.limitInWorkingHours || true,
  analystTimeout: (state) => state.userProfile.tenantOptions.analystTimeOut,
};

const actions = {
  [AUTH_REQUEST]: ({ commit }, user) =>
    new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiClient
        .authenticate(user)
        .then((resp) => {
          const resultData = resp.data;
          commit(AUTH_SUCCESS, resultData);
          resolve(resp);
        })
        .catch((err) => {
          commit(AUTH_ERROR, err);
          reject(err);
        });
    }),
  // eslint-disable-next-line no-unused-vars
  [AUTH_LOGOUT]: async ({ commit }) => {
    try {
      await apiClient.logout();
    } finally {
      commit(AUTH_LOGOUT);
      localStorage.clear();
      sessionStorage.clear();
      location.reload();
    }
  },
};

const mutations = {
  [SET_TOKEN_LOCALSTORAGE]: (state) => {
    state.token = localStorage.getItem(USER_TOKEN_KEY);
  },
  [AUTH_REQUEST]: (state) => {
    state.status = LOADING_STATUS;
  },
  [AUTH_SUCCESS]: (state, resp) => {
    resp = resp.resultData;
    const { tenantId } = resp;
    const defaultDateFormat = 'MM-DD-YYYY HH:mm:ss';
    const defaultTimezone = 'America/Chicago';
    const timeFrameInMinutes = 180; //default 3 hours

    if (!resp.tenantOptions) {
      localStorage.setItem(DATE_FORMAT_KEY, defaultDateFormat);
      localStorage.setItem(TENANT_ID_KEY, tenantId);
      localStorage.setItem(TIMEZONE_KEY, defaultTimezone);
      localStorage.setItem(TIME_FRAME_IN_MINUTES_KEY, timeFrameInMinutes);
    } else {
      const { tenantOptions } = resp || {};
      const userSolutions = [];
      for (const solution in tenantOptions.solutions) {
        if (tenantOptions.solutions[solution].active) {
          userSolutions.push(solution);
        }
      }

      if (resp.role === rolesObject.ANALYST) {
        const analystSolutions = [];
        for (const solution of userSolutions) {
          if (resp?.userSolutions?.includes(solution)) {
            analystSolutions.push(solution);
          }
        }
        localStorage.setItem(SOLUTIONS_KEY, analystSolutions);

        // save analyst pkyc solutions to localStorage
        if (userSolutions.includes(SOLUTIONS.pkyc)) {
          const pkycEntities = resp.pkycEntities || [];
          pkycEntities.length && localStorage.setItem(PKYC_ENTITIES, pkycEntities.join(', ').toLowerCase());
        }
      } else {
        localStorage.setItem(SOLUTIONS_KEY, userSolutions);
      }

      const tenantTimeframe = {
        wireTimeframe: tenantOptions?.solutions[SOLUTIONS.wire]?.global?.timeFrameInMinutes || timeFrameInMinutes,
        achTimeframe: tenantOptions?.solutions[SOLUTIONS.ach]?.global?.timeFrameInMinutes || timeFrameInMinutes,
        pkycTimeframe: tenantOptions?.solutions[SOLUTIONS.pkyc]?.global?.timeFrameInMinutes || timeFrameInMinutes,
        tfTimeframe: tenantOptions?.solutions[SOLUTIONS.tf]?.global?.timeFrameInMinutes || timeFrameInMinutes,

      };

      localStorage.setItem(DATE_FORMAT_KEY, tenantOptions?.dateFormat || defaultDateFormat);
      localStorage.setItem(TENANT_ID_KEY, tenantId || 'no-tenant');
      localStorage.setItem(TIMEZONE_KEY, tenantOptions?.timezone || defaultTimezone);
      localStorage.setItem(TIME_FRAME_IN_MINUTES_KEY, JSON.stringify(tenantTimeframe));
      localStorage.setItem(USE_BATCH,  tenantOptions?.batch?.isAvailable || false);
      localStorage.setItem(COMPARE_NAMES_KEY, tenantOptions?.compareNames || false);
      localStorage.setItem(SHOW_PKYC,tenantOptions.showPkyc);
      if (tenantOptions.shouldPersist !== undefined){
        localStorage.setItem(SHOULD_PERSIST_KEY, resp.tenantOptions.shouldPersist);
      }
    }
    
    localStorage.setItem(SESSION_ID_KEY, resp.sessionId);
    localStorage.setItem(USER_TOKEN_KEY, resp.token);
    localStorage.setItem(USER_ROLE_KEY, resp.role);
    localStorage.setItem(USER_READ_ONLY_KEY, resp.readOnly || false);
    localStorage.setItem(USER_NAME_KEY, resp.username);
    localStorage.setItem(USER_ID_KEY, resp.id);
    apiClient.setToken(resp.token);
    
    state.status = SUCCESS_STATUS;
    state.userId = resp.id;
    state.token = resp.token;
    state.readOnlyUser = resp.readOnly || false;
    state.role = resp.role;
    state.userName = resp.username;
    state.userProfile = resp;
    state.hasLoadedOnce = true;
    state.tenantId = resp.tenantId;
    state.passwordResetRequired = resp.passwordResetRequired;
    state.use2FA = resp.tenantOptions?.passwordConfig?.use2FA || false;

  },
  [AUTH_ERROR]: (state, err) => {
    state.status = ERROR_STATUS;
    state.errors = err.message;
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state) => {
    state.userProfile = null;
    state.token = '';
    state.role = '';
    state.userName = '';
    state.status = '';
    state.tenantId = '';
    state.errors = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
